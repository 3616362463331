import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rworganisasi: {
      id: '',
      nama: '',
      jenis: {
        id: ''
      },
      kedudukan: '',
      waktu: {
        mulai: '',
        selesai: ''
      },
      tempat: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rworganisasi = value
    },
  }
})
