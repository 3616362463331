import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rworganisasi: {
      id: '',
      nama: '',
      jenis: {
        id: ''
      },
      kedudukan: '',
      waktu: {
        mulai: '',
        selesai: ''
      },
      tempat: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rworganisasi = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
